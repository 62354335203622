import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL, channels } from "../../../services/constants";
import moment from "moment";
import { getAccessToken } from "../../../services/commonFunctions";
let accounts;

// fetching a driver's WhatsApp messages
export const MESSAGES_FETCHED = "MESSAGES_FETCHED";

const messagesFetched = (messages) => ({
  type: MESSAGES_FETCHED,
  payload: messages,
});

//Reset the recovered coach message on submitting message
// export const RESET_COACH_MESSAGE_ON_MSG_SUBMIT =
//   "RESET_COACH_MESSAGE_ON_MSG_SUBMIT";

// export const resetCoachMessageOnMsgSubmit = () => ({
//   type: RESET_COACH_MESSAGE_ON_MSG_SUBMIT,
// });

// resetting the messages already loaded
export const MESSAGES_RESETTED = "MESSAGES_RESETTED";

export const messagesReset = () => ({
  type: MESSAGES_RESETTED,
});

//Setting the status of all in-app messages in front-end as seen
export const SET_DOUBLE_TICK_TO_ALL_IN_APP_MESSAGES = "SET_DOUBLE_TICK_TO_ALL_IN_APP_MESSAGES";


const setDoubleTickToAllInAppMessages = () => ({
  type: SET_DOUBLE_TICK_TO_ALL_IN_APP_MESSAGES
});

// setting the loader to true
export const MESSAGES_LOADING_TRUE = "MESSAGES_LOADING_TRUE";

const messagesAreLoading = () => ({
  type: MESSAGES_LOADING_TRUE,
});

// setting the loader to true
export const MESSAGES_LOADING_FALSE = "MESSAGES_LOADING_FALSE";

const messagesAreNotLoading = () => ({
  type: MESSAGES_LOADING_FALSE,
});

// no more messages are available, so don't load anymore for this driver
export const MESSAGES_LOADING_NO_MORE = "MESSAGES_LOADING_NO_MORE";

const messagesNoMore = () => ({
  type: MESSAGES_LOADING_NO_MORE,
});

export const loadMessages =
  (driverId, isFirstLoad) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    const { messages, driver, messagesLoading } = store;
    let alreadyLoadedCount = 0;
    let loadAmount = 20; // change it to 30 later? 30 is default
    let token = auth?.accessToken;

    if (messagesLoading === true) {
      return; // don't send request if there are messages already loading
    }

    if (driver && messages) {
      if (driver.driverId === driverId && messagesLoading === null) {
        // if request is for same driver, for whom no messages are available
        return;
      }
    }

    // Check if messages are already loaded
    if (driver && messages && messages.length > 0) {
      if (isFirstLoad !== "initialLoad") {
        alreadyLoadedCount = messages.length; // if messages are loaded, skip them
      } else {
        alreadyLoadedCount = 0;
      }
      // Check if the request is for the same driver
      if (driver.driverId === driverId && isFirstLoad === "initialLoad") {
        // if request is for the same driver and the first fetch, dont' send API request
        return;
      }
      if (driver.driverId !== driverId && isFirstLoad === "initialLoad") {
        // Delete messages if the driver loaded is not the same as the request & continue with the request
        dispatch(messagesReset());
      }
      // so if the request is for the same driver, and is not the initial load, continue w the request!
    }

    dispatch(messagesAreLoading());

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .get(
        `${baseURL}/portal/coaching/Message/GetMessages?driverId=${driverId}&take=${loadAmount}&skip=${alreadyLoadedCount}` // test driverId: 9a37394a-39c4-4b2a-b632-095756d9c529
        // dummy@sd-insights.eu: 3ca1e5bd-9f60-408e-b34a-000c09662cbe
      ) // take=30 by default (max 200), skip=any num above 0
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from msgAPI, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(messagesFetched(response.body));
        dispatch(messagesAreNotLoading());
        if (response.body.length === 0) {
          dispatch(messagesNoMore());
        }
      })
      .catch((err) => console.log(`Error while fetching messages: ${err}`));
  };

// sending a new message
export const MESSAGE_CREATE_SUCCESS = "MESSAGE_CREATE_SUCCESS";

const messageSent = (message) => ({
  type: MESSAGE_CREATE_SUCCESS,
  payload: message,
});

export const sendMessage =
  (message, driverId, channelType, translate) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    const channelId = channels.find(
      (channel) => channel.channelType === channelType
    ).channelId;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    const body = {
      driverId: driverId,
      text: message,
      channelId: channelId
    }

    request
      .post(
        `${baseURL}/portal/coaching/Message/PostFreeFormWhatsappTextMessage?translate=${translate}`
      )
      .set("Content-Type", "application/json")
      .send(body)
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from msgAPI, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(messageSent(response.body));
      })
      .catch(console.error);
  };

// get the latest message's timestamp to compare with the current state => reset and refetch if needed
export const compareLatestMessage =
  (driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    const { messages } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }
    request
      .get(
        `${baseURL}/portal/coaching/Message/GetLatestMessageTime?driverId=${driverId}`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(2, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from msgAPI, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        if (response.noContent) {
          // 204 no content if no latest message has been found
          return;
        }
        if (messages != null && messages.length > 0) {
          // compare the latest with the response
          const currentLastItemDate = messages[messages.length - 1].date;
          const convertedResponseDate = moment.utc(response.body.latestMessageTime).local();
          if (convertedResponseDate.isSame(currentLastItemDate)) {
            // No new message available, but check seen status of in-app messages
            let revMessages = [...messages];
            //Find last in-app message and the seen status in front end
            let currentLastItem = revMessages.reverse().find((message) => message.channelType === 2);
            let currentLastItemStatus = currentLastItem ? currentLastItem.seen : undefined;
            //console.log("Same timestamp and status: " + currentLastItemStatus);
            // The in-app message status in backend is true, update it in front end
            if (response.body.seen === true && currentLastItemStatus === undefined) {
              //console.log("Set seen as true for all");
              dispatch(setDoubleTickToAllInAppMessages());
            }

          }
          else if (!convertedResponseDate.isSame(currentLastItemDate)) {
            // reset and refetch messages if there's a new message available
            //console.log("Different timestamp")
            dispatch(messagesReset());
            dispatch(loadMessages(driverId, "initialLoad"));
          }
        } else {
          // no loaded messages, but there is a new one => reset & refetch
          //console.log("Else part loading")
          dispatch(messagesReset());
          dispatch(loadMessages(driverId, "initialLoad"));
        }
      })
      .catch(console.error);
  };

// fetching available templates for WhatsApp templated messaging
export const TEMPLATES_FETCHED = "TEMPLATE S_FETCHED";

const templatesFetched = ({ templates, isSpanishCoach }) => ({
  type: TEMPLATES_FETCHED,
  payload: { templates, isSpanishCoach },
});

export const loadTemplates = () => async (dispatch, getState) => {
  const store = getState();
  let { auth, roles } = store;
  const { templates } = store;
  let token = auth?.accessToken;

  if (templates) {
    // if templates are loaded, don't send the request again (it's unlikely to have changed since)
    return;
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  //checking if the logged in coach is a spanish coach. They have new role "lang_spanish"
  const isSpanishCoach = roles?.find((role) => role.roleString === "lang_spanish") ? true : false;

  // console.log(isSpanishCoach);
  request
    .get(
      `${baseURL}/portal/coaching/Message/GetTemplates` // available params: ?isActive={bool}&isComplete={bool} => by default these are true
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from msgAPI, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(templatesFetched({ templates: response.body, isSpanishCoach }));
    })
    .catch((err) => console.log(`Error while fetching templates: ${err}`));
};

// sending a new templated message
export const TEMPLATED_MESSAGE_CREATE_SUCCESS =
  "TEMPLATED_MESSAGE_CREATE_SUCCESS";

const templatedMessageSent = (message) => ({
  type: TEMPLATED_MESSAGE_CREATE_SUCCESS,
  payload: message,
});

export const sendTemplatedMessage =
  (
    driverId,
    templateName,
    templateNamespaceId,
    paramsArray,
    channelType,
    message
  ) =>
    async (dispatch, getState) => {
      const store = getState();
      let { auth } = store;
      let token = auth?.accessToken;
      const channelId = channels.find(
        (channel) => channel.channelType === channelType
      ).channelId;

      // Get accessToken
      if (auth != null) {
        if (auth.accessToken == null) {
          try {
            accounts = authProvider.getAllAccounts();
            token = await getAccessToken(accounts, authProvider);
            auth = getState().auth;
          } catch (e) {
            console.log(e);
          }
        }
      }

      const body = {
        driverId: driverId,
        text: message,
        channelId: channelId
      }
      //Check if channel type is In-app
      if (channelType === 2) {
        request
          .post(
            `${baseURL}/portal/coaching/Message/PostFreeFormWhatsappTextMessage?translate=${false}`
          )
          .set("Content-Type", "application/json")
          .send(body)
          .set("Authorization", `Bearer ${token}`)
          .retry(1, (err, res) => {
            if (res.unauthorized) {
              console.log(`401 error, retry from msgAPI, err is ${err}`);
              dispatch({
                type: "ACQUIRE_TOKEN_FAILURE",
                payload: null,
              });
            }
          })
          .then((response) => {
            dispatch(messageSent(response.body));
          })
          .catch(console.error);
      } else if (channelType === 1) {
        request
          .post(
            `${baseURL}/portal/coaching/Message/PostTemplatedWhatsappTextMessage?driverId=${driverId}&templateName=${templateName}&templateNamespaceName=${templateNamespaceId}`
          )
          .set("Content-Type", "application/json") // req body has to be formatted to JSON
          .send(paramsArray) // array of objects: for each parameter, there is a name, a type and the text you want to fill in
          .set("Authorization", `Bearer ${token}`)
          .retry(1, (err, res) => {
            if (res.unauthorized) {
              console.log(`401 error, retry from msgAPI, err is ${err}`);
              dispatch({
                type: "ACQUIRE_TOKEN_FAILURE",
                payload: null,
              });
            }
          })
          .then((response) => {
            if (response.ok) {
              dispatch(templatedMessageSent(response.body));
            }
          })
          .catch(console.error);
      }
    };




// setting the loader to true
export const TRANSLATION_LOADING_TRUE = "TRANSLATION_LOADING_TRUE";

const translationLoading = () => ({
  type: TRANSLATION_LOADING_TRUE,
});

// setting the loader to true
export const TRANSLATION_LOADING_FALSE = "TRANSLATION_LOADING_FALSE";

const translationAreNotLoading = () => ({
  type: TRANSLATION_LOADING_FALSE,
});

export const TRANSLATION_FETCHED = "TRANSLATION_FETCHED";

const translationFetched = (translatedText) => ({
  type: TRANSLATION_FETCHED,
  payload: translatedText
});

//updating current messages redux slice with fetched translation
export const UPDATE_MESSAGE_WITH_TRANSLATION = "UPDATE_MESSAGE_WITH_TRANSLATION";

const updateMessageWithTranslation = ({ messageId, translationResponse }) => ({
  type: UPDATE_MESSAGE_WITH_TRANSLATION,
  payload: { messageId, translationResponse }
});

export const getTranslationOfMessage = (messageId, coachLanguage) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  // const { templates } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  dispatch(translationLoading())
  request
    .post(
      `${baseURL}/portal/coaching/Message/MessageTranslateDriverToCoach?MessageId=${messageId}&CoachLanguageCode=${coachLanguage}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from msgAPI, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      //sending translation, this slice is not used now, we can use it if wnat to add a loader
      dispatch(translationFetched(response.body))
      //updating messages redux store
      dispatch(updateMessageWithTranslation({ messageId: messageId, translationResponse: response.body }))
      dispatch(translationAreNotLoading())
    })
    .catch((err) => console.log(`Error while translating APIs: ${err}`));
};


//updating current messages redux slice with fetched translation
export const UPDATE_MESSAGE_WITH_MEDIA = "UPDATE_MESSAGE_WITH_MEDIA";

const updateMessageWithmedia = ({ messageId,mediaResponse }) => ({
  type: UPDATE_MESSAGE_WITH_MEDIA,
  payload: { messageId, mediaResponse }
});

export const getMediaFromMessage = (messageId) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  // const { templates } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  // dispatch(translationLoading())
  request
    .get(
      `${baseURL}/portal/coaching/Message/GetMessageMedia?messageId=${messageId}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from msgAPI, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then(async (response) => {
      dispatch(updateMessageWithmedia({ messageId: messageId, mediaResponse:response.body }))
    })
    .catch((err) => console.log(`Error while translating APIs: ${err}`));
};